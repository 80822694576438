import React from 'react';

const Contact = () => (
    <main id="contact-container">
        <h1>Let's Get In Touch</h1>
        <h3>Email</h3>
        <p>ladyleahmattern@gmail.com</p>
        <h3>Social Media</h3>
        <p>Linkedin</p>
        <p>Twitter</p>
    </main>
);

export default Contact;